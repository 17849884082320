<template>
  <div class="container">
    <order-details-modal
      :visible="order != null"
      :order="order"
      :show-loader="updating"
      @cancel="handleOrderDetailsModalHidden"
      @accepted="handleOrderAccept"
      @completed="handleOrderCompleted"
      @file-uploaded="handleNewFileUploaded"
      :forward-options="forwardUsers"
      @forwarded="handleOrderForwarded"
      @forwarde-removed="handleOrderForwardRemoved"
      @kg-waste-collected-added="handKgWasteCollectedAdded"
    >
    </order-details-modal>
    <orders-inbox
      :is-loading="isLoading"
      :active-order-category="activeOrderCategory"
      :total-orders-in-category="totalOrdersInCategory"
      :orders="orders"
      :forward-options="forwardUsers"
      :can-fetch-more-items="nextPage != -1"
      :is-fetching-items="isFetchingItems"
      @fetch-more-requested="handleFetchMoreItems"
      @category-clicked="handleCategoryChanged"
      @show-order="handleShowOrderClicked"
      @accepted="handleOrderAccept"
      @forwarded="handleOrderForwarded"
      @forwarde-removed="handleOrderForwardRemoved"
      @completed="handleOrderCompleted"
    />
  </div>
</template>

<script>
import authHelper from "@/auth";
import PageLoader from "@/app/components/layout/PageLoader.vue";
import SupplierOrdersService from "../../services/supplier-orders.service";
import OrdersInbox from "../../components/OrdersInbox.vue";
import OrderDetailsModal from "../../components/OrderDetailsModal.vue";
import { mapActions, mapGetters } from "vuex";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

export default {
  components: {
    PageLoader,
    OrdersInbox,
    OrderDetailsModal,
    ConfirmModal,
  },
  data() {
    return {
      isLoading: true,
      isFetchingItems: false,
      activeOrderCategory: "INBOX",
      orders: [],
      currentPage: 1,
      nextPage: -1,
      totalOrdersInCategory: 0,
      ordersPageSize: 100,

      updating: false,
      order: null,
    };
  },
  async mounted() {
    try {
      await this.handleCategoryChanged("INBOX"); // Set default active category
      // We need to fetch order info
      if (this.$route.name == "suppliers.orders.show") {
        var orderResult = await service.getOderById(this.$route.params.id);
        this.order = orderResult.data.data;
      }

      if (
        authHelper.getRole() == "Supplier" ||
        authHelper.getRole() == "SuperAdmin"
      )
        await this.fetchForwardUsers();
    } catch {
      alert(this.T("Web.Generic.Errors.Unknown", "Unknown error"));
    }
  },
  computed: {
    ...mapGetters({
      forwardUsers: "accountUsers/list",
      notifications: "notification/getNotifications",
    }),
    notificationsLength() {
      return this.notifications.length;
    },
  },
  methods: {
    ...mapActions({ fetchForwardUsers: "accountUsers/fetchList" }),
    handleOrderDetailsModalHidden() {
      this.order = null;
      this.$router.push({
        name: "suppliers.orders.list",
        params: { contactId: this.$route.params.contactId },
      });
    },
    async handleFetchMoreItems() {
      try {
        this.isFetchingItems = true;
        this.currentPage = this.nextPage;
        this.nextPage = -1;
        await this.fetcData();
      } catch {
        alert(this.T("Web.Generic.Errors.Unknown", "Unknown error"));
      } finally {
        this.isFetchingItems = false;
      }
    },
    async handleCategoryChanged(newCategory) {
      try {
        this.isLoading = true;
        this.orders = [];
        this.currentPage = 1;
        this.nextPage = -1;
        this.totalOrdersInCategory = 0;
        this.activeOrderCategory = newCategory;
        await this.fetcData();
      } catch {
        alert(this.T("Web.Generic.Errors.Unknown", "Unknown error"));
      } finally {
        this.isLoading = false;
      }
    },
    async fetcData() {
      const service = new SupplierOrdersService();
      var orderStatus = null;
      switch (this.activeOrderCategory) {
        case "INBOX":
          orderStatus = "Created";
          break;
        case "CONFIRMED":
          orderStatus = "Accepted";
          break;
        case "CLOSED":
          orderStatus = "Collected";
          break;
      }
      var ordersPaged = (
        await service.getPagedOrders(
          this.currentPage,
          this.ordersPageSize,
          orderStatus
        )
      ).data.data;

      this.orders = [...this.orders, ...ordersPaged.items];
      this.totalOrdersInCategory = ordersPaged.paging.totalItems;
      this.nextPage = ordersPaged.paging.nextPage;
    },
    async handleShowOrderClicked(order) {
      const service = new SupplierOrdersService();
      var orderResult = await service.getOderById(order.id);
      this.order = orderResult.data.data;
      this.$router.push({
        name: "suppliers.orders.show",
        params: { id: order.id },
      });
    },
    async handleOrderAccept(order) {
      const service = new SupplierOrdersService();
      await service.acceptOrderById(order.id);
      var orderToUpdate = this.orders.find((o) => o.id == order.id);
      orderToUpdate.status = "Accepted";
      order.status = "Accepted";
      this.handleOrderDetailsModalHidden();
      this.$notify({
        group: "default",
        title: this.T(
          "Web.Generic.Ordering.Suppliers.OrderAccepted",
          "Order accepted!"
        ),
        type: "success",
      });
    },
    async handleOrderCompleted(order) {
      const service = new SupplierOrdersService();
      await service.completeOrderById(order.id);
      var orderToUpdate = this.orders.find((o) => o.id == order.id);
      orderToUpdate.status = "Collected";
      order.status = "Collected";
      this.handleOrderDetailsModalHidden();
      this.$notify({
        group: "default",
        title: this.T(
          "Web.Generic.Ordering.Suppliers.OrderMarkedAsCollected",
          "Order marked as collected!"
        ),
        type: "success",
      });
    },
    async handleNewFileUploaded(order) {
      this.updating = true;
      const service = new SupplierOrdersService();
      var orderResult = await service.getOderById(order.id);
      this.order.files = orderResult.data.data.files;
      this.updating = false;
    },
    async handleOrderForwarded(data) {
      try {
        this.updating = true;
        const service = new SupplierOrdersService();
        await service.forwardOrder(data.order.id, data.user.id);
        var orderResult = await service.getOderById(data.order.id);
        var orderToUpdate = this.orders.find((o) => o.id == data.order.id);
        orderToUpdate.forwardedUser = orderResult.data.data.forwardedUser;
        if (this.order != null)
          this.order.forwardedUser = orderResult.data.data.forwardedUser;
        if (data.createRule) {
          await service.addOrderForwardRule(
            data.order.orderItem.id,
            data.user.id
          );
        }
        this.updating = false;
        this.$notify({
          group: "default",
          title: this.T(
            "Web.Generic.Ordering.Suppliers.OrderForwarded",
            "Order forwarded!"
          ),
          type: "success",
        });
      } catch {
        alert(this.T("Web.Generic.Errors.Unknown", "Unknown error"));
      }
    },
    async handleOrderForwardRemoved(order) {
      try {
        this.updating = true;
        const service = new SupplierOrdersService();
        await service.removeForward(order.id);
        var orderToUpdate = this.orders.find((o) => o.id == order.id);
        orderToUpdate.forwardedUser = null;
        orderToUpdate.forwardedUser = null;
        if (this.order != null) this.order.forwardedUser = null;
        this.updating = false;
        this.$notify({
          group: "default",
          title: this.T(
            "Web.Generic.Ordering.Suppliers.ForwardRemoved",
            "Forward removed!"
          ),
          type: "success",
        });
      } catch {
        alert(this.T("Web.Generic.Errors.Unknown", "Unknown error"));
      }
    },
    async handKgWasteCollectedAdded(data) {
      try {
        this.updating = true;
        const service = new SupplierOrdersService();
        await service.addKgWasteCollected(data.id, data.kgWasteCollected);
        this.updating = false;
        this.$notify({
          group: "default",
          title: this.T(
            "Web.Generic.Ordering.Suppliers.KgWasteCollectedAdded",
            "Kg added to order"
          ),
          type: "success",
        });
      } catch {
        alert(this.T("Web.Generic.Errors.Unknown", "Unknown error"));
      }
    },
  },
  watch: {
    async notificationsLength(newValue, oldValue) {
      if (newValue <= oldValue) return;
      const service = new SupplierOrdersService();
      var ordersResult = await service.getOrders();
      this.orders = ordersResult.data.data;
    },
  },
};
</script>